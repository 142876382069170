@import './colors.less';

:root {
  /* Liste des couleurs : https://www.figma.com/file/WpaitEea7ORxKf90Q3UqWm/Ices?node-id=4%3A31 */
  --PRIMARY-180: @primary-180;
  --PRIMARY-160: @primary-160;
  --PRIMARY-140: @primary-140;
  --PRIMARY-120: @primary-120;
  --PRIMARY: @primary-100;
  --PRIMARY-080: @primary-080;
  --PRIMARY-060: @primary-060;
  --PRIMARY-040: @primary-040;
  --PRIMARY-020: @primary-020;

  --SECONDARY-180: @secondary-180;
  --SECONDARY-160: @secondary-160;
  --SECONDARY-140: @secondary-140;
  --SECONDARY-120: @secondary-120;
  --SECONDARY: @secondary-100;
  --SECONDARY-080: @secondary-080;
  --SECONDARY-060: @secondary-060;
  --SECONDARY-040: @secondary-040;
  --SECONDARY-020: @secondary-020;

  --GREEN-180: @green-180;
  --GREEN-160: @green-160;
  --GREEN-140: @green-140;
  --GREEN-120: @green-120;
  --GREEN: @green-100;
  --GREEN-080: @green-080;
  --GREEN-060: @green-060;
  --GREEN-040: @green-040;
  --GREEN-020: @green-020;

  --RED-180: @red-180;
  --RED-160: @red-160;
  --RED-140: @red-140;
  --RED-120: @red-120;
  --RED: @red-100;
  --RED-080: @red-080;
  --RED-060: @red-060;
  --RED-040: @red-040;
  --RED-020: @red-020;

  --YELLOW-180: @yellow-180;
  --YELLOW-160: @yellow-160;
  --YELLOW-140: @yellow-140;
  --YELLOW-120: @yellow-120;
  --YELLOW: @yellow-100;
  --YELLOW-080: @yellow-080;
  --YELLOW-060: @yellow-060;
  --YELLOW-040: @yellow-040;
  --YELLOW-020: @yellow-020;

  --GREY-160: @grey-160;

  --CANCEL-ERROR: @error-100;

  --WARNING: @warning-100;

  --VALID: @valid-100;

  --WHITE: @white-180;
  --WHITE-100: @white-100;
  --WHITE-020: @white-020;

  --BLACK: @black-180;
  --BLACK-140: @black-140;
  --BLACK-100: @black-100;
  --BLACK-060: @black-060;
  --BLACK-020: @black-020;
}


/* FONT */
@font-face {
  font-family: "Montserrat-Black";
  src: url("./Montserrat/Montserrat-Black.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("./Montserrat/Montserrat-BlackItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("./Montserrat/Montserrat-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("./Montserrat/Montserrat-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./Montserrat/Montserrat-ExtraBold.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("./Montserrat/Montserrat-ExtraBoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("./Montserrat/Montserrat-ExtraLight.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url("./Montserrat/Montserrat-ExtraLightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("./Montserrat/Montserrat-Italic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("./Montserrat/Montserrat-Light.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("./Montserrat/Montserrat-LightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./Montserrat/Montserrat-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("./Montserrat/Montserrat-MediumItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./Montserrat/Montserrat-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./Montserrat/Montserrat-SemiBold.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("./Montserrat/Montserrat-SemiBoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url("./Montserrat/Montserrat-Thin.ttf") format("ttf");
}
@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("./Montserrat/Montserrat-ThinItalic.ttf") format("ttf");
}
h1 {
  font-family: "Montserrat-Bold";
  font-size: 1.52em !important;
}

h2 {
  font-family: "Montserrat-Regular";
  font-size: 1.52em !important;
}

h3 {
  font-family: "Montserrat-Bold";
  font-size: 1.24em !important;
}

* {
  font-family: "Montserrat-Regular";
  font-size: 0.97em;
}


/* WHITE */

.white {
  background-color: var(--WHITE) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }
}

.white-100 {
  background-color: var(--WHITE-100) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }
}

.white-020 {
  background-color: var(--WHITE-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }
}


/* BLACK */

.black {
  background-color: var(--BLACK) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }
}

.black-140 {
  background-color: var(--BLACK-140) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }
}

.black-100 {
  background-color: var(--BLACK-100) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }
}

.black-060 {
  background-color: var(--BLACK-060) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }
}

.black-020 {
  background-color: var(--BLACK-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }
}


/* PRIMARY */

.primary-180 {
  background-color: var(--PRIMARY-180) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-180) !important;
    color: var(--PRIMARY-180) !important;
    
    * {
      color: var(--PRIMARY-180) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-160 {
  background-color: var(--PRIMARY-160) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-160) !important;
    color: var(--PRIMARY-160) !important;
    
    * {
      color: var(--PRIMARY-160) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-140 {
  background-color: var(--PRIMARY-140) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-140) !important;
    color: var(--PRIMARY-140) !important;
    
    * {
      color: var(--PRIMARY-140) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-120 {
  background-color: var(--PRIMARY-120) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-120) !important;
    color: var(--PRIMARY-120) !important;
    
    * {
      color: var(--PRIMARY-120) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary {
  background-color: var(--PRIMARY) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &:hover {
    background-color: var(--PRIMARY-140) !important;
  }

  &:active {
    background-color: var(--PRIMARY-180) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY) !important;
    color: var(--PRIMARY) !important;
    
    * {
      color: var(--PRIMARY) !important;
    }

    &:hover {
      border-color: var(--PRIMARY-180) !important;
      color: var(--PRIMARY-180) !important;
    }
  
    &:active {
      border-color: var(--PRIMARY-180) !important;
      color: var(--PRIMARY-180) !important;
      background-color: rgba(157, 196, 219, 0.1) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-080 {
  background-color: var(--PRIMARY-080) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-080) !important;
    color: var(--PRIMARY-080) !important;
    
    * {
      color: var(--PRIMARY-080) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-060 {
  background-color: var(--PRIMARY-060) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-060) !important;
    color: var(--PRIMARY-060) !important;
    
    * {
      color: var(--PRIMARY-060) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-040 {
  background-color: var(--PRIMARY-040) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-040) !important;
    color: var(--PRIMARY-040) !important;
    
    * {
      color: var(--PRIMARY-040) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.primary-020 {
  background-color: var(--PRIMARY-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--PRIMARY-020) !important;
    color: var(--PRIMARY-020) !important;
    
    * {
      color: var(--PRIMARY-020) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

/* SECONDARY */

.secondary-180 {
  background-color: var(--SECONDARY-180) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-180) !important;
    color: var(--SECONDARY-180) !important;
    
    * {
      color: var(--SECONDARY-180) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-160 {
  background-color: var(--SECONDARY-160) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-160) !important;
    color: var(--SECONDARY-160) !important;
    
    * {
      color: var(--SECONDARY-160) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-140 {
  background-color: var(--SECONDARY-140) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-140) !important;
    color: var(--SECONDARY-140) !important;
    
    * {
      color: var(--SECONDARY-140) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-120 {
  background-color: var(--SECONDARY-120) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-120) !important;
    color: var(--SECONDARY-120) !important;
    
    * {
      color: var(--SECONDARY-120) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary {
  background-color: var(--SECONDARY) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY) !important;
    color: var(--SECONDARY) !important;
    
    * {
      color: var(--SECONDARY) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-080 {
  background-color: var(--SECONDARY-080) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-080) !important;
    color: var(--SECONDARY-080) !important;
    
    * {
      color: var(--SECONDARY-080) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-060 {
  background-color: var(--SECONDARY-060) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-060) !important;
    color: var(--SECONDARY-060) !important;
    
    * {
      color: var(--SECONDARY-060) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-040 {
  background-color: var(--SECONDARY-040) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-040) !important;
    color: var(--SECONDARY-040) !important;
    
    * {
      color: var(--SECONDARY-040) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.secondary-020 {
  background-color: var(--SECONDARY-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--SECONDARY-020) !important;
    color: var(--SECONDARY-020) !important;
    
    * {
      color: var(--SECONDARY-020) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

/* CANCEL-ERROR */

.cancel-error {
  background-color: var(--CANCEL-ERROR) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &:hover {
    background-color: var(--CANCEL-ERROR-140) !important;
  }

  &:active {
    background-color: var(--CANCEL-ERROR-180) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--CANCEL-ERROR) !important;
    color: var(--CANCEL-ERROR) !important;
    
    * {
      color: var(--CANCEL-ERROR) !important;
    }

    &:hover {
      border-color: var(--CANCEL-ERROR-180) !important;
      color: var(--CANCEL-ERROR-180) !important;
    }
  
    &:active {
      border-color: var(--CANCEL-ERROR-180) !important;
      color: var(--CANCEL-ERROR-180) !important;
      background-color: rgba(157, 196, 219, 0.1) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}


/* WARNING */

.warning {
  background-color: var(--WARNING) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--WARNING) !important;
    color: var(--WARNING) !important;
    
    * {
      color: var(--WARNING) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}


/* VALID */

.valid {
  background-color: var(--VALID) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--VALID) !important;
    color: var(--VALID) !important;
    
    * {
      color: var(--VALID) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}


/* VARIANTS */
/* GREEN */

.green-180 {
  background-color: var(--GREEN-180) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-180) !important;
    color: var(--GREEN-180) !important;
    
    * {
      color: var(--GREEN-180) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-160 {
  background-color: var(--GREEN-160) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-160) !important;
    color: var(--GREEN-160) !important;
    
    * {
      color: var(--GREEN-160) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-140 {
  background-color: var(--GREEN-140) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-140) !important;
    color: var(--GREEN-140) !important;
    
    * {
      color: var(--GREEN-140) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-120 {
  background-color: var(--GREEN-120) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-120) !important;
    color: var(--GREEN-120) !important;
    
    * {
      color: var(--GREEN-120) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green {
  background-color: var(--GREEN) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &:hover {
    background-color: var(--GREEN-140) !important;
  }

  &:active {
    background-color: var(--GREEN-180) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN) !important;
    color: var(--GREEN) !important;
    
    * {
      color: var(--GREEN) !important;
    }

    &:hover {
      border-color: var(--GREEN-180) !important;
      color: var(--GREEN-180) !important;
    }
  
    &:active {
      border-color: var(--GREEN-180) !important;
      color: var(--GREEN-180) !important;
      background-color: rgba(157, 196, 219, 0.1) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-080 {
  background-color: var(--GREEN-080) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-080) !important;
    color: var(--GREEN-080) !important;
    
    * {
      color: var(--GREEN-080) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-060 {
  background-color: var(--GREEN-060) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-060) !important;
    color: var(--GREEN-060) !important;
    
    * {
      color: var(--GREEN-060) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-040 {
  background-color: var(--GREEN-040) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-040) !important;
    color: var(--GREEN-040) !important;
    
    * {
      color: var(--GREEN-040) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.green-020 {
  background-color: var(--GREEN-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--GREEN-020) !important;
    color: var(--GREEN-020) !important;
    
    * {
      color: var(--GREEN-020) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

/* RED */

.red-180 {
  background-color: var(--RED-180) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-180) !important;
    color: var(--RED-180) !important;
    
    * {
      color: var(--RED-180) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-160 {
  background-color: var(--RED-160) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-160) !important;
    color: var(--RED-160) !important;
    
    * {
      color: var(--RED-160) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-140 {
  background-color: var(--RED-140) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-140) !important;
    color: var(--RED-140) !important;
    
    * {
      color: var(--RED-140) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-120 {
  background-color: var(--RED-120) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-120) !important;
    color: var(--RED-120) !important;
    
    * {
      color: var(--RED-120) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red {
  background-color: var(--RED) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &:hover {
    background-color: var(--RED-140) !important;
  }

  &:active {
    background-color: var(--RED-180) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED) !important;
    color: var(--RED) !important;
    
    * {
      color: var(--RED) !important;
    }

    &:hover {
      border-color: var(--RED-180) !important;
      color: var(--RED-180) !important;
    }
  
    &:active {
      border-color: var(--RED-180) !important;
      color: var(--RED-180) !important;
      background-color: rgba(157, 196, 219, 0.1) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-080 {
  background-color: var(--RED-080) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-080) !important;
    color: var(--RED-080) !important;
    
    * {
      color: var(--RED-080) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-060 {
  background-color: var(--RED-060) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-060) !important;
    color: var(--RED-060) !important;
    
    * {
      color: var(--RED-060) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-040 {
  background-color: var(--RED-040) !important;
  color: var(--WHITE) !important;
  
  * {
    color: var(--WHITE) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-040) !important;
    color: var(--RED-040) !important;
    
    * {
      color: var(--RED-040) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}

.red-020 {
  background-color: var(--RED-020) !important;
  color: var(--BLACK) !important;
  
  * {
    color: var(--BLACK) !important;
  }

  &.ghost, &[ghost] {
    background-color: transparent !important;
    border-color: var(--RED-020) !important;
    color: var(--RED-020) !important;
    
    * {
      color: var(--RED-020) !important;
    }

    &[disabled] {
      background-color: transparent !important;  
      border-color: var(--BLACK-060) !important;
    }
  }

  &[disabled] {
    background-color: var(--WHITE-020) !important;  
    
    * {
      color: var(--BLACK-060) !important;
    } 
  }
}
@primary-color: #21A0C6;@info-color: #115063;@success-color: #67C23A;@processing-color: #115063;@error-color: #FF5555;@highlight-color: #FF5555;@warning-color: #FFCE48;@normal-color: #6F6F6F;