.ant-notification-notice-close {
    display: none;
}

.ant-notification {
    svg {
        font-size: 2.5em !important;
    }
    .anticon-info-circle {
        color: var(--PRIMARY) !important;
    }

    .anticon-check-circle {
        color: var(--GREEN) !important;
    }

    .anticon-close-circle {
        color: var(--RED) !important;
    }

    .anticon-exclamation-circle {
        color: var(--YELLOW) !important;
    }

    .title {
        font-size: 16px !important;
        font-weight: 700;

        &.info-type {
            color: var(--PRIMARY) !important;
        }
    
        &.success-type {
            color: var(--GREEN) !important;
        }
    
        &.error-type {
            color: var(--RED) !important;
        }
    
        &.warning-type {
            color: var(--YELLOW) !important;
        }
    }
}

@primary-color: #21A0C6;@info-color: #115063;@success-color: #67C23A;@processing-color: #115063;@error-color: #FF5555;@highlight-color: #FF5555;@warning-color: #FFCE48;@normal-color: #6F6F6F;