.ant-card-body {
  padding: 0 !important;
}

#download,
#cloud,
#delete {
  font-size: 2em !important;
}

#download {
  color: var(--PRIMARY);
}

#cloud {
  color: var(--SECONDARY);
}

#delete {
  color: var(--RED);
}

.ant-card-head {
  margin: 0;
  padding: 0;
}

.title-card{
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 100px;

  .title-container{
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      overflow: hidden;
      max-width: 280px;
      padding-left: 20px;
      padding-right: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      align-self: center;
      color: var(--GREY-160);
      font-weight: 600;
      font-size: 16px !important;
    }
  }
}

.container {
  min-height: 32px;
}

.tag-container {
  display: flex;
  align-self: baseline;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  max-width: 260px;

  span[role='tag'] {
    margin-bottom: 10px;
    min-width: 70px;
  }
}

.ant-card-head-title {
  padding-bottom: 6px;
}
@primary-color: #21A0C6;@info-color: #115063;@success-color: #67C23A;@processing-color: #115063;@error-color: #FF5555;@highlight-color: #FF5555;@warning-color: #FFCE48;@normal-color: #6F6F6F;