.ant-modal-content {
  background: linear-gradient(135deg, rgba(252, 133, 94, 0.5), rgba(101, 253, 233, 0.5), rgba(244, 113, 255, 0.5), rgba(101, 253, 233, 0.5));
  padding: 1px;
  div {
    background: white;
    color: black;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

.storybook-modal::before {
  background: linear-gradient(135deg, rgba(252, 133, 94, 0.5), rgba(101, 253, 233, 0.5), rgba(244, 113, 255, 0.5), rgba(101, 253, 233, 0.5));
  animation: 3s linear 0s infinite alternate modal_animation;
  content: "";
  filter: blur(25px);
  height: calc(100% - 40px);
  left: 20px;
  position: absolute;
  top: 20px;
  width: calc(100% - 40px);
}

@keyframes modal_animation {
  to {
    transform: scale(1.05);
  }
}
@primary-color: #21A0C6;@info-color: #115063;@success-color: #67C23A;@processing-color: #115063;@error-color: #FF5555;@highlight-color: #FF5555;@warning-color: #FFCE48;@normal-color: #6F6F6F;