.storybook-button {
  .ant-radio-group  {
    .active {
      background: var(--PRIMARY) !important;
      border-color: var(--PRIMARY) !important;
      color: var(--WHITE) !important;
    }

    .unactive{
      background: var(--WHITE) !important;
      border-color: var(--PRIMARY) !important;
      color: var(--PRIMARY) !important;
    }

    svg {
      font-size: 1em !important;
    }
  }

  button {
    display: flex;
    align-items: center;

    background: var(--PRIMARY) !important;
    border-color: var(--PRIMARY) !important;
    color: var(--WHITE-100) !important;

    font-family: "Montserrat";
    font-size: 1em !important;
    font-weight: 500;

    * {
      font-family: "Montserrat";
      font-size: 1em !important;
    }

    &[data-large] {
      font-size: 1.2em !important;

      * {
        font-family: "Montserrat";
        font-size: 1.2em !important;
      }
    }

    &[data-small] {    
      font-size: 0.8em !important;

      * {
        font-family: "Montserrat";
        font-size: 0.8em !important;
      }
    }
    
    * {
      color: var(--WHITE-100) !important;
    }

    &:hover {
      background: var(--PRIMARY-080) !important;
      border-color: var(--PRIMARY-080) !important;
    }

    &:active {
      background: var(--PRIMARY-140) !important;
      border-color: var(--PRIMARY-140) !important;
    }

    &[data-link] {
      background: transparent !important;
      border: none !important;
      color: var(--PRIMARY) !important;
      
      * {
        color: var(--PRIMARY) !important;
      }

      &:hover {
        * {
          color: var(--PRIMARY-080) !important;
        }
      }

      &:active {
        * {
          color: var(--PRIMARY-140) !important;
        }
      }

      &[disabled] {
        background: var(--WHITE-020) !important;  
        border-color: var(--PRIMARY-060) !important;
        
        * {
          color: var(--PRIMARY-060) !important;
        } 
      }
    }

    &[disabled] {
      background: var(--PRIMARY-020) !important;
      border-color: var(--PRIMARY-060) !important;
      
      * {
        color: var(--PRIMARY-060) !important;
      } 
    }
  
    &[data-ghost] {
      background: transparent !important;
      border-color: var(--PRIMARY) !important;
      color: var(--PRIMARY) !important;
      
      * {
        color: var(--PRIMARY) !important;
      }

      &:hover {
        border-color: var(--PRIMARY-080) !important;
        color: var(--PRIMARY-080) !important;

        * {
          color: var(--PRIMARY-080) !important;
        }
      }

      &:active {
        border-color: var(--PRIMARY-140) !important;
        color: var(--PRIMARY-140) !important;

        * {
          color: var(--PRIMARY-140) !important;
        }
      }

      &[disabled] {
        background: var(--WHITE-020) !important;  
        border-color: var(--PRIMARY-060) !important;
        
        * {
          color: var(--PRIMARY-060) !important;
        } 
      }
    }

    &[data-danger] {
      background: var(--CANCEL-ERROR) !important;
      border-color: var(--CANCEL-ERROR) !important;
      color: var(--WHITE-100) !important;
      
      * {
        color: var(--WHITE-100) !important;
      }

      &:hover {
        background: var(--RED-080) !important;
        border-color: var(--RED-080) !important;
      }


      &[data-link] {
        background: transparent !important;
        border: none !important;
        color: var(--RED) !important;
        
        * {
          color: var(--RED) !important;
        }

        &:hover {
          * {
            color: var(--RED-080) !important;
          }
        }

        &:active {
          * {
            color: var(--RED-140) !important;
          }
        }

        &[disabled] {
          background: var(--WHITE-020) !important;  
          border-color: var(--RED-060) !important;
          
          * {
            color: var(--RED-060) !important;
          } 
        }
      }

      &:active {
        background: var(--RED-140) !important;
        border-color: var(--RED-140) !important;
      }

      &[disabled] {
        background: var(--RED-020) !important;  
        border-color: var(--RED-060) !important;
        
        * {
          color: var(--RED-060) !important;
        } 
      }

      &[data-ghost] {
        background: transparent !important;
        border-color: var(--CANCEL-ERROR) !important;
        color: var(--CANCEL-ERROR) !important;
        
        * {
          color: var(--CANCEL-ERROR) !important;
        }

        &:hover {
          border-color: var(--RED-080) !important;
          color: var(--RED-080) !important;
  
          * {
            color: var(--RED-080) !important;
          }
        }
  
        &:active {
          border-color: var(--RED-140) !important;
          color: var(--RED-140) !important;
  
          * {
            color: var(--RED-140) !important;
          }
        }
  
        &[disabled] {
          background: var(--WHITE-020) !important;  
          border-color: var(--RED-060) !important;
          
          * {
            color: var(--RED-060) !important;
          } 
        }
      }
    }

    &.cta {
      background: var(--SECONDARY) !important;
      border-color: var(--SECONDARY) !important;
      color: var(--WHITE-100) !important;
      
      * {
        color: var(--WHITE-100) !important;
      }

      &:hover {
        background: var(--SECONDARY-080) !important;
        border-color: var(--SECONDARY-080) !important;
      }


      &[data-link] {
        background: transparent !important;
        border: none !important;
        color: var(--SECONDARY) !important;
        
        * {
          color: var(--SECONDARY) !important;
        }

        &:hover {
          * {
            color: var(--SECONDARY-080) !important;
          }
        }

        &:active {
          * {
            color: var(--SECONDARY-140) !important;
          }
        }

        &[disabled] {
          background: var(--WHITE-020) !important;  
          border-color: var(--SECONDARY-060) !important;
          
          * {
            color: var(--SECONDARY-060) !important;
          } 
        }
      }

      &:active {
        background: var(--SECONDARY-140) !important;
        border-color: var(--SECONDARY-140) !important;
      }

      &[disabled] {
        background: var(--SECONDARY-020) !important;  
        border-color: var(--SECONDARY-060) !important;
        
        * {
          color: var(--SECONDARY-060) !important;
        } 
      }

      &[data-ghost] {
        background: transparent !important;
        border-color: var(--SECONDARY) !important;
        color: var(--SECONDARY) !important;
        
        * {
          color: var(--SECONDARY) !important;
        }

        &:hover {
          border-color: var(--SECONDARY-080) !important;
          color: var(--SECONDARY-080) !important;

          * {
            color: var(--SECONDARY-080) !important;
          }
        }

        &:active {
          border-color: var(--SECONDARY-140) !important;
          color: var(--SECONDARY-140) !important;

          * {
            color: var(--SECONDARY-140) !important;
          }
        }

        &[disabled] {
          background: var(--WHITE-020) !important;  
          border-color: var(--SECONDARY-060) !important;
          
          * {
            color: var(--SECONDARY-060) !important;
          } 
        }
      }
    }
  }
}
@primary-color: #21A0C6;@info-color: #115063;@success-color: #67C23A;@processing-color: #115063;@error-color: #FF5555;@highlight-color: #FF5555;@warning-color: #FFCE48;@normal-color: #6F6F6F;